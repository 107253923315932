import {
  faAws,
  faConfluence,
  faCss3Alt,
  faDocker,
  faGitSquare,
  faHtml5,
  faJava,
  faJenkins,
  faJira,
  faJsSquare,
  faNodeJs,
  faNpm,
  faReact,
  faSass,
  faWordpress,
} from '@fortawesome/free-brands-svg-icons';

import styles from './Skills.module.scss';

import Section from '../section';

import { Typescript } from '../../assets';
import SkillCard from '../skillCard';

const SKILLS: any = [
  { color: '#F0DB4F', icon: faJsSquare, name: 'JavaScript' },
  { color: '#61dbfb', icon: faReact, name: 'ReactJS' },
  { color: '#E44D26', icon: faHtml5, name: 'HTML5' },
  { color: '#264de4', icon: faCss3Alt, name: 'CSS3' },
  { color: '#ce679a', icon: faSass, name: 'SASS' },
  { color: '#cc3534', icon: faNpm, name: 'NPM' },
  { color: '#00749c', icon: faWordpress, name: 'WordPress' },
  { color: '#F1502F', icon: faGitSquare, name: 'GIT' },
  { color: '#FF9900', icon: faAws, name: 'AWS' },
  { color: '#d33833', icon: faJenkins, name: 'Jenkins' },
  { color: '#003366', icon: faJira, name: 'Jira' },
  { color: '#1f83fd', icon: faConfluence, name: 'Confluence' },
  { color: '#099cec', icon: faDocker, name: 'Docker' },
  { color: '#68A063', icon: faNodeJs, name: 'NodeJS' },
  { color: '#5382a1', icon: faJava, name: 'JAVA J2EE' },
];
/**
* Skills page is all about my skills which I
* have gained from different technologies and company
*
* @author Yadab Nepal
*/
function Skills() {
  return (
    <Section id="yadab-skills" inverse title="SKILLS">
      <div className={styles.skills}>
        <SkillCard name="TypeScript">
          <Typescript className={styles.typeScript} />
        </SkillCard>
        {SKILLS.map(({ color, icon, name }: any) => (
          <SkillCard
            color={color}
            icon={icon}
            key={name}
            name={name}
          />
        ))}
      </div>
    </Section>
  );
}

export default Skills;
