import React from 'react';

function AuthenticatedRoutes() {
  return (
    <div>
      Hello
    </div>
  );
}

export default AuthenticatedRoutes;
