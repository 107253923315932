/**
* Main app of my portfolio which contains all the sections
* like Homepage, aboutme, resume, contact, skill and projects
*
* @author Yadab Nepal
*/
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'particles.js';

import './App.css';

import { particleConfig } from './constants';
import Providers from './providers';
import PortfolioRoutes from './routes/PortfolioRoutes';

function App() {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    window.particlesJS('particles-js', particleConfig);
  }, []);

  return (
    <Providers>
      <BrowserRouter>
        <PortfolioRoutes />
      </BrowserRouter>
    </Providers>
  );
}

export default App;
