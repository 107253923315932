/**
* This class contains a button which is fixed at bottom left
* It contains all of my social profiles link
*
* @author Yadab Nepal
*/
import EmailIcon from '@mui/icons-material/Email';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import InstagramIcon from '@mui/icons-material/Instagram';

import './floatingButton.css';

import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';

const socials = [
  {
    icon: <EmailIcon sx={{ color: 'teal' }} />,
    link: 'mailto:contact@yadabn.com.np',
    name: 'Email',
  },
  {
    icon: <FacebookIcon sx={{ color: '#3B5998' }} />,
    link: 'https://www.facebook.com/yadab.nepal.779',
    name: 'Facebook',
  },
  {
    icon: <TwitterIcon sx={{ color: '#33ccff' }} />,
    link: 'https://www.twitter.com/samipnepal',
    name: 'Twitter',
  },
  {
    icon: <LinkedInIcon sx={{ color: '#007bb7' }} />,
    link: 'https://www.linkedin.com/in/yadabnepal',
    name: 'LinkedIn',
  },
  {
    icon: <GitHubIcon sx={{ color: '#000' }} />,
    link: 'https://www.github.com/yadabnepal',
    name: 'GitHub',
  },
  {
    icon: <InstagramIcon sx={{ color: '#3f729b' }} />,
    link: 'https://www.instagram.com/samipnepal',
    name: 'Instagram',
  },
];

function FloatingButton() {
  return (
    <SpeedDial
      ariaLabel="SpeedDial basic example"
      sx={{ bottom: 16, position: 'absolute', right: 16 }}
      icon={<SpeedDialIcon />}
    >
      {socials.map(({
        color, icon, link, name,
      }) => (
        <SpeedDialAction
          key={link}
          icon={icon}
          tooltipTitle={name}
          onClick={() => { location.href = link; }}
          style={{ backgroundColor: color }}
        />
      ))}
    </SpeedDial>
  );
}

export default FloatingButton;
