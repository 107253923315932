import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faGlobeAsia,
  faMapMarkedAlt,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { ReactNode } from 'react';

import styles from './Footer.module.scss';

import Translate from '../../components/translate';

const CONTACTS = [
  { href: 'tel:+61434969208', icon: faPhone, title: 'australia.number' },
  { href: 'tel:+9779840050904', icon: faPhone, title: 'nepal.number' },
  { href: 'mailto:nepalyadab@gmail.com', icon: faEnvelope, title: 'nepalyadab@gmail.com' },
  { href: 'mailto:contact@yadabn.com.np', icon: faEnvelope, title: 'contact@yadabn.com.np' },
];

interface ContactsProps { href: string, icon: IconProp, title: string }
interface SectionProps { children: ReactNode, title: string }

function Contacts({ href, icon, title }: ContactsProps) {
  return (
    <div className={styles.contact}>
      <FontAwesomeIcon icon={icon} />
      <a className={styles.yadabLink} href={href}>
        <Translate>{title}</Translate>
      </a>
    </div>
  );
}

function Section({ children, title }: SectionProps) {
  return (
    <div className={styles.section}>
      <div className={styles.title}>
        <Translate>{title}</Translate>
      </div>
      {children}
    </div>
  );
}

function Footer() {
  return (
    <footer id="yadab-footer" className={styles.yadabFooter}>
      <div className={styles.container}>
        <Section title="Location">
          <FontAwesomeIcon icon={faMapMarkedAlt as IconProp} size="2x" />
          <Translate>CURRENT_LOCATION</Translate>
        </Section>

        <Section title="Get In Touch">
          {CONTACTS.map(({ href, icon, title }: any) => (
            <Contacts
              href={href}
              icon={icon}
              key={title}
              title={title}
            />
          ))}
        </Section>

        <Section title="Website">
          <FontAwesomeIcon icon={faGlobeAsia as IconProp} size="2x" />
          <a className={styles.yadabLink} href="http://www.yadabn.com.np">
                        www.yadabn.com.np
          </a>
          <div>
                        &copy;
            {new Date().getFullYear()}
                        &nbsp;
            <Translate>NAME</Translate>
          </div>
        </Section>
      </div>
    </footer>
  );
}

export default Footer;
