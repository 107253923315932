import HomePage from '../../views/homePage';
import MenuBar from '../../views/menuBar';
import FloatingButton from '../../views/floatingbutton/FloatingButton';
import AboutMe from '../../views/aboutme';
import Resume from '../../views/resume/Resume';
import ContactMe from '../../views/contactme/ContactMe';
import Skills from '../../views/skills';
import Footer from '../../views/footer';

function ParticlePortfolio() {
  return (
    <main>
      <MenuBar />
      <HomePage />
      <AboutMe />
      <Resume />
      <Skills />
      <ContactMe />
      <Footer />
      <FloatingButton />
    </main>
  );
}

export default ParticlePortfolio;
