/* eslint-disable max-len */
/**
* About me page which reflects everything about me
* and my hobbies
*
* @author Yadab Nepal
*/

import styles from './AboutMe.module.scss';

import Section from '../section';

import Translate from '../../components/translate';

function AboutMe() {
  return (
    <Section id="yadab-aboutMe" inverse title="ABOUT_ME_LABEL">
      <div data-aos="fade-top" data-aos-offset="100" className={styles.aboutMeText}>
        <Translate>ABOUT_ME</Translate>
      </div>
      <h3>
        <Translate>Profile</Translate>
      </h3>
      <ol className={styles.profile}>
        <li className={styles.profileItem}>Implemented a comprehensive solutions using ReactJs, JavaScript/TypeScript, and NodeJs to enhance the platform&rsquo;s functionality and address performance bottlenecks.</li>
        <li className={styles.profileItem}>Designed and implemented a GraphQL-based API layer to enable flexible and efficient data retrieval, enhancing the platform&rsquo;s responsiveness and user experience.</li>
        <li className={styles.profileItem}>Collaborated with cross-functional teams to gather requirements, prioritize tasks, and ensure timely and successful project delivery.</li>
        <li className={styles.profileItem}>Utilized Jest and react-testing-library for unit and integration testing, ensuring robust code quality and reducing the occurrence of bugs.</li>
        <li className={styles.profileItem}>Employed Jira and Confluence for effective project management, maintaining clear documentation, and fostering collaboration among team members.</li>
        <li className={styles.profileItem}>Practiced Agile methodologies throughout the project lifecycle, participating in sprint planning, daily stand-ups, and retrospectives to drive continuous improvement.</li>
        <li className={styles.profileItem}>Provided technical guidance and mentorship to junior team members, promoting knowledge sharing and fostering professional growth.</li>
        <li className={styles.profileItem}>Presented project updates and deliverables to stakeholders, communicating progress, challenges, and proposed solutions effectively.</li>
      </ol>
    </Section>
  );
}

export default AboutMe;
