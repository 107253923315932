import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faGraduationCap, faSuitcase } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import './resume.css';

import styles from './Resume.module.scss';

import Section from '../section';

import Translate from '../../components/translate';
import { education, experience as experienceData } from '../../dataSource';

function Resume() {
    const getEducations = () => education.degrees.map((degree) => (
        <li className={degree.className} key={degree.degree}>
            <div className="timeline-badge">
                <i>
                    <FontAwesomeIcon icon={faGraduationCap as IconProp} />
                </i>
            </div>
            <div className="timeline-panel" data-aos={degree.dataAos} data-aos-offset="10">
                <div className="timeline-heading">
                    <h3 className="timeline-title">
                        <Translate>{degree.degree}</Translate>
                    </h3>
                    <span className="company">
                        <Translate>{degree.university}</Translate>
                    </span>
                </div>
                <div className="timeline-body">
                    <p>
                        <Translate>{degree.description}</Translate>
                    </p>
                </div>
            </div>
        </li>
    ));

    const getExperiences = () => experienceData.experiences.map((experience) => (
        <li className={experience.className} key={experience.role}>
            <div className="timeline-badge"><i><FontAwesomeIcon icon={faSuitcase as IconProp} /></i></div>
            <div className="timeline-panel" data-aos={experience.dataAos} data-aos-offset="10">
                <div className="timeline-heading">
                    <h3 className="timeline-title">
                        <Translate>{experience.role}</Translate>
                    </h3>
                    <span className="company">
                        <Translate>{experience.company}</Translate>
                    </span>
                </div>
                <div className="timeline-body">
                    <p>
                        <Translate>{experience.description}</Translate>
                    </p>
                </div>
            </div>
        </li>
    ));

    return (
        <Section id="yadab-resume" className={styles.container} title="Resume">
            <div className="row">
                <div className="col-md-12 col-md-offset-0">
                    <ul className="timeline">
                        <li className="timeline-heading center-align">
                            <div>
                                <h3>
                                    <Translate>Work Experience</Translate>
                                </h3>
                            </div>
                        </li>
                        {getExperiences()}
                        <br />
                        <li className="timeline-heading center-align">
                            <div>
                                <h3>
                                    <Translate>Education</Translate>
                                </h3>
                            </div>
                        </li>
                        {getEducations()}
                    </ul>
                </div>
                <div className="center-align">
                    <Translate>Download My CV</Translate>
                    <br />
                    <br />
                    <a
                        className="btn-floating btn-large blue pulse"
                        href="../../resources/cv/yadab_cv.pdf"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <i className="large"><FontAwesomeIcon icon={faDownload as IconProp} /></i>
                    </a>
                </div>
            </div>
        </Section>
    );
}

export default Resume;
