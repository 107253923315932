import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useState } from 'react';

import styles from './SkillCard.module.scss';

interface SkillCardProps {
  children?: ReactNode,
  color?: string,
  icon?: IconProp,
  name: string,
}

function SkillCard({
    children,
    color,
    icon,
    name,
}: SkillCardProps) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <div
            className={styles.container}
            data-aos="fade-up"
            data-aos-offset="100"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            role="presentation"
        >
            {(!children && icon) && <FontAwesomeIcon color={isHovered ? color : ''} icon={icon} size="8x" />}
            {(!icon && children) && children}
            {name}
        </div>
    );
}

export default SkillCard;
