import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import M from 'materialize-css/dist/js/materialize';

import './contactMe.css';

import Section from '../section';

import Translate from '../../components/translate';

const SPAM_MESSAGES = ['fuck', 'suck', 'f*ck', 'fu*k'];

class ContactMe extends Component {
  constructor(props) {
    super(props);

    this.state = { email: '', message: '', name: '' };
  }

  handleSubmit(event) {
    const { name, message, email } = this.state;

    event.preventDefault();

    if (name.length < 3) {
      this.showNotification('Invalid Name');
      return;
    }

    if (!/^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      this.showNotification('Invalid Email');
      return;
    }

    if (message.length < 20 || SPAM_MESSAGES.includes(message)) {
      this.showNotification('Please write proper message');
      return;
    }

    this.sendFeedback({
      from_name: name,
      message_html: message,
      reply_to: email,
    });
  }

  async sendFeedback(variables) {
    try {
      await emailjs.send('smtp_server', 'template_SNQWXex7', variables, 'user_eoxI4xVggGpdkUOZw0OUQ');

      this.setState({ email: '', message: '', name: '' });
      this.showNotification('Email successfully sent!');
    } catch (error) {
      this.showNotification('Error sending email');
      console.error('Oh well, you failed. Here some thoughts on the error that occured:', error);
    }
  }

  showNotification(message) {
    M.Toast.dismissAll();
    M.toast({ classes: 'rounded', html: message });
  }

  render() {
    const { t } = this.props;
    const { email, message, name } = this.state;

    return (
      <Section id="yadab-contact" title="Contact Me">
        <form>
          <div className="row center-align">
            <Translate>Dear Yadab,</Translate>
          </div>
          <div className="row">
            <div className="col s12 l4 right-align rightToCenterText">
              <Translate>My name is</Translate>
            </div>
            <div className="col s12 l4">
              <input
                className="inputBox"
                minLength="3"
                onChange={(event) => this.setState({ name: event.target.value })}
                placeholder={t('(your name here)')}
                required
                type="text"
                value={name}
              />
            </div>
            <div className="col s12 l4 left-align leftToCenterText">
              <Translate>and my</Translate>
            </div>
          </div>
          <div className="row">
            <div className="col s12 l4 right-align rightToCenterText">
              <Translate>email address is</Translate>
            </div>
            <div className="col s12 l4">
              <input
                className="inputBox"
                onChange={(event) => this.setState({ email: event.target.value })}
                placeholder={t('(your email address)')}
                required
                type="email"
                value={email}
              />
            </div>
            <div className="col s12 l4 left-align leftToCenterText">
              <Translate>I have a</Translate>
            </div>
          </div>
          <div className="row">
            <div className="col s12 center-align">
              <Translate>message for you,</Translate>
            </div>
            <div className="col s12">
              <textarea
                className="inputBox"
                id="textarea"
                placeholder={t('(your msg here)')}
                onChange={(event) => {
                  event.target.style.height = '10vh';
                  event.target.style.height = `${20 + event.target.scrollHeight}px`;
                  this.setState({ message: event.target.value });
                }}
                value={message}
                required
              />
            </div>
          </div>
          <div className="send-button center-align">
            <button name="action" onClick={(event) => this.handleSubmit(event)}>
              <FontAwesomeIcon icon={faPaperPlane} size="3x" />
            </button>
          </div>
        </form>
      </Section>
    );
  }
}

export default withTranslation('translations')(ContactMe);
