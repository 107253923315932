import M from 'materialize-css/dist/js/materialize';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './MenuBar.module.scss';
import './menuBar.css';

import Translate from '../../components/translate';
import nepalFlagIcon from '../../resources/images/nepal_flag_icon.png';
import ukFlagIcon from '../../resources/images/united_kingdom_flag_icon.png';

const MENU_ITEMS = [
  { href: '#yadab-aboutMe', label: 'ABOUT' },
  { href: '#yadab-resume', label: 'RESUME' },
  { href: '#yadab-skills', label: 'SKILLS' },
  { href: '#yadab-contact', label: 'CONTACT' },
];

class MenuBar extends Component {
  constructor(props) {
    super(props);

    this.state = { isScrolling: false, selectedLanguage: 'en' };
  }

  componentDidMount() {
    const selectedLanguage = sessionStorage.getItem('yadabnLanguage');

    if (selectedLanguage) {
      this.setState({ selectedLanguage });
    }

    window.addEventListener('scroll', () => this.handleScrollChange());
  }

  handleScrollChange() {
    const { isScrolling } = this.state;

    if (window.scrollY > 10 && !isScrolling) {
      this.setState({ isScrolling: true });

      return;
    }

    if (window.scrollY <= 10 && isScrolling) {
      this.setState({ isScrolling: false });
    }
  }

  handleLanguageChange(code) {
    const { i18n } = this.props;
    const { selectedLanguage } = this.state;

    if (selectedLanguage === code) return;

    sessionStorage.setItem('yadabnLanguage', code);

    this.setState({ selectedLanguage: code });
    i18n.changeLanguage(code);
    M.Toast.dismissAll();
    M.toast({
      classes: 'rounded',
      html: `Language changed to ${code === 'np' ? 'Nepali' : 'English'}`,
    });
  }

  render() {
    const { isScrolling, selectedLanguage } = this.state;

    return (
      <nav className={`navbar-fixed ${isScrolling ? 'fixed-me' : ''}`}>
        <div className={styles.container}>
          <div className="nav-wrapper">
            <a href="/" className="yadabGreeting">
              <Translate>GREETING</Translate>
            </a>
            <div className="right">
              <ul className="left hide-on-med-and-down">
                {MENU_ITEMS.map(({ href, label }) => (
                  <li key={href}>
                    <a href={href}>
                      <Translate>{label}</Translate>
                    </a>
                  </li>
                ))}
              </ul>
              <div id="lang-switch" className="right">
                <button
                  className={`waves-effect np ${selectedLanguage === 'np' ? 'active-flag' : ''}`}
                  onClick={() => this.handleLanguageChange('np')}
                  type="button"
                  value="np"
                >
                  <img src={nepalFlagIcon} alt="np" />
                </button>
                <button
                  className={`waves-effect en ${selectedLanguage === 'en' ? 'active-flag' : ''}`}
                  onClick={() => this.handleLanguageChange('en')}
                  type="button"
                  value="en"
                >
                  <img src={ukFlagIcon} alt="en" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default withTranslation('translations')(MenuBar);
