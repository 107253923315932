import { ReactNode } from 'react';

import styles from './Section.module.scss';

import Translate from '../../components/translate';

interface SectionProps {
    children: ReactNode,
    className?: string,
    id?: string,
    inverse?: boolean,
    title: string,
}

function Section({
    children,
    className,
    id = '',
    inverse = false,
    title = '',
}: SectionProps) {
    return (
        <section className={`${styles.container} ${inverse ? styles.inverseContainer : ''} ${className}`} id={id}>
            <div className={styles.sectionContent}>
                <h1
                    className={`${styles.title} ${inverse ? styles.inverseTitle : ''}`}
                    data-aos="fade-in"
                    data-aos-delay="100"
                >
                    <Translate>{title}</Translate>
                </h1>
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </section>
    );
}

export default Section;
