import styles from './HomePage.module.scss';

import Translate from '../../components/translate';

function HomePage() {
  return (
    <div className={styles.container}>
      <div id="particles-js" className={styles.particle}>
        <div className={styles.title}>
          <h1>
            <Translate>TITLE</Translate>
          </h1>
          <div className={styles.description}>
            <Translate>SOFTWARE_ENGINEER</Translate>
          </div>
        </div>
        <div className={styles.stage}>
          <div className={`${styles.box} ${styles.bounce}`} />
        </div>
      </div>
    </div>
  );
}

export default HomePage;
