// eslint-disable-next-line import/prefer-default-export
export const particleConfig = {
    particles: {
        color: {
            value: '#ffffff',
        },
        interactivity: {
            events: {
                onclick: {
                    enable: false,
                },
            },
        },
        line_linked: {
            color: '#ffffff',
            distance: 150,
            enable: false,
            opacity: 0.4,
            width: 1,
        },
        move: {
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 600,
            },
            bounce: false,
            direction: 'none',
            enable: true,
            out_mode: 'out',
            random: true,
            speed: 2,
            straight: false,
        },
        number: {
            value: 200,
        },
        opacity: {
            anim: {
                enable: true,
                opacity_min: 0,
                speed: 1,
                sync: false,
            },
            random: true,
            value: 1,
        },
        shape: {
            image: {
                height: 100,
                src: 'img/github.svg',
                width: 100,
            },
            polygon: {
                nb_sides: 5,
            },
            stroke: {
                color: '#000000',
                width: 0,
            },
            type: 'circle',
        },
        size: {
            anim: {
                enable: false,
                size_min: 0.3,
                speed: 4,
                sync: false,
            },
            random: true,
            value: 3,
        },
    },
    retina_detect: true,
};
