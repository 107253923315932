import { Auth0Provider } from '@auth0/auth0-react';
import { ReactNode } from 'react';

interface ProvidersProps { children: ReactNode }

function Providers({ children }: ProvidersProps) {
    return (
        <Auth0Provider
            domain="yourportfolio.us.auth0.com"
            clientId="KJzOvHslTkTeFzmR7Ua7dlxsyKgXvhrH"
            redirectUri="http://localhost:3000/callback"
        >
            {children}
        </Auth0Provider>
    );
}

export default Providers;
