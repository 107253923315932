import { Route, Routes } from 'react-router-dom';

import ParticlePortfolio from '../portfolios/particlePortfolio';
import AuthenticatedRoutes from './authenticatedRoutes';

function PortfolioRoutes() {
  return (
    <Routes>
      <Route element={<ParticlePortfolio />} path="/" />
      <Route element={<AuthenticatedRoutes />} path="*" />
    </Routes>
  );
}

export default PortfolioRoutes;
