import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-xhr-backend';

import englishTranslation from './locales/en/translation.json';
import nepaliTranslation from './locales/np/translation.json';

i18n
    .use(XHR)
    .use(LanguageDetector)
    .init({
        debug: false,
        defaultNS: 'translations',
        fallbackLng: sessionStorage.getItem('yadabnLanguage') || 'en',
        interpolation: {
            escapeValue: false,
        },
        keySeparator: false,
        lng: sessionStorage.getItem('yadabnLanguage') || 'en',
        ns: ['translations'],
        resources: {
            en: {
                translations: englishTranslation,
            },
            np: {
                translations: nepaliTranslation,
            },
        },
    });

export default i18n;
